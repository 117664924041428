import {
  type Integration,
  type IntegrationName,
  type TranscriptionService,
} from "carbon-connect";

export const getCarbonIntegrations = (
  integrationName: typeof IntegrationName,
  transcriptionService: typeof TranscriptionService,
): Integration[] => {
  return [
    {
      id: integrationName.LOCAL_FILES,
      chunkSize: 1500,
      overlapSize: 20,
      maxFileSize: 20000000,
      maxFilesCount: 10,
      skipEmbeddingGeneration: false,
      useOcr: false,
      parsePdfTablesWithOcr: false,
      generateSparseVectors: false,
      prependFilenameToChunks: false,
      maxItemsPerChunk: undefined,
      transcriptionService: transcriptionService.ASSEMBLYAI,
      includeSpeakerLabels: false,
      generateChunksOnly: false,
      filesTabColumns: ["name", "status", "created_at"],
      allowedFileTypes: [
        {
          extension: "TXT",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
        {
          extension: "PDF",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          useOcr: true,
          generateSparseVectors: false,
        },
        {
          extension: "MD",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
        {
          extension: "MSG",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
        {
          extension: "EML",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
        {
          extension: "HTML",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
        {
          extension: "JSON",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
        {
          extension: "PPTX",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
        {
          extension: "TSV",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
        {
          extension: "RTF",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
        {
          extension: "DOCX",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
        {
          extension: "CSV",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
        {
          extension: "XLSM",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
        {
          extension: "XLSX",
          chunkSize: 1500,
          overlapSize: 20,
          skipEmbeddingGeneration: false,
          generateSparseVectors: false,
        },
      ],
    },
    {
      id: integrationName.NOTION,
      chunkSize: 1500,
      overlapSize: 20,
      skipEmbeddingGeneration: false,
      generateSparseVectors: false,
      prependFilenameToChunks: false,
      showFilesTab: true,
      syncSourceItems: true,
      useCarbonFilePicker: false,
      incrementalSync: true,
      filesTabColumns: ["name", "status", "external_url", "created_at"],
    },
    {
      id: integrationName.ZENDESK,
      chunkSize: 1500,
      overlapSize: 20,
      skipEmbeddingGeneration: false,
      generateSparseVectors: false,
      prependFilenameToChunks: false,
      syncFilesOnConnection: true,
      showFilesTab: true,
      syncSourceItems: true,
      useCarbonFilePicker: false,
      incrementalSync: true,
      filesTabColumns: ["name", "status", "external_url", "created_at"],
    },
    {
      id: integrationName.SALESFORCE,
      chunkSize: 1500,
      overlapSize: 20,
      skipEmbeddingGeneration: false,
      generateSparseVectors: false,
      prependFilenameToChunks: false,
      syncFilesOnConnection: true,
      showFilesTab: true,
      syncSourceItems: true,
      useCarbonFilePicker: false,
      filesTabColumns: ["name", "status", "external_url", "created_at"],
    },
    {
      id: integrationName.WEB_SCRAPER,
      chunkSize: 1500,
      overlapSize: 20,
      sitemapEnabled: true,
      recursionDepth: 3,
      maxPagesToScrape: 100,
      skipEmbeddingGeneration: false,
      enableAutoSync: false,
      generateSparseVectors: false,
      syncFilesOnConnection: true,
      htmlTagsToSkip: [],
      cssClassesToSkip: [],
      cssSelectorsToSkip: [],
      prependFilenameToChunks: false,
      generateChunksOnly: false,
    },
    {
      id: integrationName.GOOGLE_DRIVE,
      chunkSize: 1500,
      overlapSize: 20,
      skipEmbeddingGeneration: false,
      generateSparseVectors: false,
      prependFilenameToChunks: false,
      maxItemsPerChunk: undefined,
      showFilesTab: false,
      useOcr: true,
      parsePdfTablesWithOcr: true,
      syncSourceItems: true,
      useCarbonFilePicker: false,
      incrementalSync: true,
      filesTabColumns: ["name", "status", "external_url", "created_at"],
    },
  ];
};
