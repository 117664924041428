import React, { useState } from "react";
import { Popover, PopoverTrigger, PopoverContent } from "@appsmith/ads";

import ModulesSection from "./ModulesSection";
import { createMessage, MODULES_TITLE } from "ee/constants/messages";
import { IDEHeaderEditorSwitcher } from "IDE";
import { useSelector } from "react-redux";
import { getShowModuleReference } from "ee/selectors/moduleFeatureSelectors";

const PackageHeaderEditorTitle = ({ title }: { title: string }) => {
  const [active, setActive] = useState(false);

  const showModuleReference = useSelector(getShowModuleReference);

  if (showModuleReference) {
    return null;
  }

  const closeMenu = () => {
    setActive(false);
  };

  return (
    <Popover onOpenChange={setActive} open={active}>
      <PopoverTrigger>
        <IDEHeaderEditorSwitcher
          active={active}
          prefix={createMessage(MODULES_TITLE)}
          title={title}
          titleTestId="t-modules-switcher"
        />
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="!p-0 !pb-1"
        onEscapeKeyDown={closeMenu}
      >
        <ModulesSection onItemSelected={closeMenu} />
      </PopoverContent>
    </Popover>
  );
};

export default PackageHeaderEditorTitle;
